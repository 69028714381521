<template>
<!-- <v-container class="tableouter blue" fluid> -->
    <v-container class="tableouter d-flex flex-column pa-0 pr-6" fluid>
      <v-dev>
        <v-text-field
            class=""
            v-model="filter"
            label="Filter"
            outlined
            @input="onFilter"
          ></v-text-field>
      </v-dev>
      <v-dev
        class="flex-grow-1"
        outlined
        tile>
        <ag-grid-vue style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="rowData"
          rowSelection="single"
          suppressCellSelection="true"
          suppressRowClickSelection="true"
          suppressClickEdit="true"
          @grid-ready="onGridReady">
        </ag-grid-vue>
      </v-dev>
    </v-container>
</template>

<script>
    import { AgGridVue } from "ag-grid-vue";
    import TaskService from '@/services/task.service'
    import { parseJSON } from 'date-fns'

    export default {
        name: 'App',
        data() {
          return {
            filter: "",
            defaultColDef: null,
            columnDefs: null,
            rowData: null
          }
        },
        components: {
            AgGridVue
        },
        methods: {
            onFilter() {
              this.gridApi.setQuickFilter(this.filter);
            },
            onGridReady(params) {
              console.log("on grid ready")
              this.gridApi = params.api;
              this.columnApi = params.columnApi;
            },
            getSelectedRows() {
                console.log("getSelectedRows")
                const selectedNodes = this.gridApi.getSelectedNodes();
                const selectedData = selectedNodes.map( node => node.data );
                const selectedDataStringPresentation = selectedData.map( node => `${node.make} ${node.model}`).join(', ');
                alert(`Selected nodes: ${selectedDataStringPresentation}`);
            }
        },
        beforeMount() {
            this.columnDefs = [
                {
                  field: 'task_type',
                  headerName: 'Rodzaj',
                  cellRenderer: data => {
                    console.log(data.value)
                    switch(data.value) {
                      case 1:
                      return 'Operator'
                      case 2:
                      return 'Patrol'
                      default:
                      return data.value
                    }
                  },
                },
                {
                  field: 'created_at',
                  headerName: 'Data',
                  valueFormatter: param => {
                    return parseJSON(param.value).toLocaleString(
                      window.navigator.language,
                      {timeStyle: "medium",dateStyle: "medium"}
                      )
                  }
                },
                {
                  field: 'closed_at',
                  headerName: 'Zakonczenie',
                  valueFormatter: param => {
                    if (param.value == null) {
                      return ""
                    }
                    return parseJSON(param.value).toLocaleString(
                      window.navigator.language,
                      {timeStyle: "medium",dateStyle: "medium"}
                      )
                  }
                },
                {
                  flex: 5,
                  field: 'event.code.text',
                  sortable: true,
                  checkboxSelection: false,
                  pinned: false,
                  getQuickFilterText: params => {
                      return params.value; //custom value
                  }
                },
                { field: 'event.site.code_name',
                  headerName: 'Krypto',
                  width: 90,
                  pinned: false,
                },
                { field: 'event.site.name'},
                { field: 'event.site.address'},
                { field: 'description'},
                {
                  flex: 1,
                  field: '',
                  resizable: false
                }
            ];
            this.defaultColDef = {
              resizable: true,
              sortable: true,
              suppressMovable:true
            };
            this.gridOptions = {
              // Your grid options here....
              suppressCellSelection: true
            };
            TaskService.fetchAll().then(response => {
                // console.log('fetch site', response.data)
                this.rowData = response.data
              })
              .catch(error => {
                console.log('my new token', error)
              })
            // fetch('https://www.ag-grid.com/example-assets/small-row-data.json')
            //   .then(result => result.json())
            //   .then(rowData => this.rowData = rowData);
            // this.rowData = [
            //     { make: 'Toyota', model: 'Celica', price: 35000 },
            //     { make: 'Ford', model: 'Mondeo', price: 32000 },
            //     { make: 'Porsche', model: 'Boxter', price: 72000 }
            // ];
        }
    }
</script>

<style lang="sass">
  .tableouter
    height: 100%
    width: 100%
    position:  absolute
</style>